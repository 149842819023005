import styled, {keyframes} from 'styled-components';
import backgroundImg from '../../utils/images/back_azul.png';
import { font_base } from '../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../data/color';

export const Container = styled.div`
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh; 

`;
export const DepthBox = styled.div`
  position: relative;
  width: 30%; 
  height: 90%;
  background-color: ${branco_primario};
  border-radius: 5px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  margin: 0% auto; 
  padding: 30vw; 
  overflow: hidden; 
  @media (max-width: 768px) {
    padding: 70vw;
  }
  @media (max-width: 1024px) {
    padding: 33vw;
  }
`;

export const TopBar = styled.div`
  background-color: ${branco_primario};
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  display: flex;
  @media (max-width: 768px) {
    padding: 0.0%;
  }
  img{
    width: 50px;
    height: 50px;
    margin: 5px;
  }
  
 `;

export const TopBarBlue = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%; 
  height: 40px;
  background-color: ${azul_primario}; 
`;

export const SubTitle = styled.h2`
  margin: 0;
  color: ${azul_primario};
  font-size: 16px;
  text-align: center;
  font-family: ${font_base};
`;

export const SubTitleWapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 100%;
  margin-left: 40%;
`;

export const ArrowIcon = styled.span`
  position: fixed;
  top: 3%;
  left: 80.5%;
  transition: background-color 0.3s; 
  &:hover {
    color: ${azul_primario}; 
  }
`;
export const ProfileWrapper = styled.div`

`;

export const ParallelInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -80%;
  margin-top: -70%;
  width: 120%;
  
`;

export const VoltarButtonWrapper = styled.div`
  position: fixed;
  margin-top: 0%;
  margin-left: 52.5%;
  z-index: 800;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: ${rotate} 1s linear infinite;
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999; 
`;

export const MenuText = styled.p`
  margin: 0;
  padding: 0;
  color: ${azul_primario};
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  font-family: ${font_base};
`;

export const Title = styled.h2`
  font-weight: 300;
  color: ${cinza_texto};
  font-size: 1.25rem;
  text-align: center;
  font-family: ${font_base};
`;

export const TitleWapper = styled.div`
  display: flex;
  margin: 10;
  padding: 10;
  height: 1%;
  margin-left: -80%;
  margin-top:  -10%;
  
`;  

export const SmallDiv = styled.div`
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  padding: 0vw;
  position: relative;
  width: 250%;
  max-height: 300px; /* Altura máxima desejada */
  min-height: 100px; /* Altura máxima desejada */
  background-color: ${branco_primario};
  overflow-y: auto; /* Habilitando apenas o scroll vertical */
  margin: 0 auto;
  margin-bottom: 80px;
  left: -80%;
`;

export const TopBarBlueSmallDiv = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 30px;
  background-color: ${azul_primario};
  display: flex;
`;

export const TextTopBarBlueLinhasWrapper = styled.div`
  width: 60%;
  height: 50px;
  display: flex;
`;

export const TextContentLinhasWrapper = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
`;

export const TextTopBarBlueSmallLinhaWrapper = styled.div`
  color: ${branco_primario};
  margin-top: 5px;
  width: 16.6%;
  height: 50px;
  display: flex;
  justify-content: center;
  font-family: ${font_base};
`;

export const TextContentSmallLinhaWrapper = styled.div`
  width: 16.6%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center;
  height: auto;
  hyphens: auto;
`;

export const TextTopBarBlueSmallWrapper = styled.div`
  color: ${branco_primario};
  font-family: ${font_base};
  margin-top: 5px;
  width: 10%;
  height: 50px;
  display: flex;
  justify-content: center;
`;

export const TextContentSmallWrapper = styled.div`
  width: 10%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${font_base};
`;

export const MapInfoButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const WhiteTitle = styled.h2`
  font-weight: 300;
  color: #E7E7E7;
  font-size: 1rem;
  margin-left: 10px;
  font-family: ${font_base};
`;

export const ParallelGetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -90%;
  margin-top: 0%;
`;

export const DividerWrapper = styled.div`
  margin-top: -22%;
  margin-bottom: 50px;
`;

// Wrapper para a seleção de dia
export const DaySelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  left: -20%;
`;

// Botão para seleção de dia
export const DaySelectorButton = styled.button`
 background-color: ${({ selected }) => (selected ? azul_primario : branco_primario)};
  color: ${({ selected }) => (selected ? branco_primario : azul_primario)};
  border: 1px solid ${azul_primario};
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-family: ${font_base};
  width:150px;

  &:hover {
    background-color: ${({ selected }) => (selected ? azul_primario : azul_primario_light)};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const ResultDiv = styled.div`
  display: flex;
  background-color: ${branco_primario};
  border-bottom: 1px solid #ccc;
  border-color: #dee2e6;
  top: 20px;
  position: relative;
  width: 100%;  
`;

export const Divider = styled.div`
    padding: 8px 8px 8px 0;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    width: 90%;
    text-align: left;
    outline: none;
    background-color: ${branco_primario};
    color: #495057;
    position: absolute;
    left: 7%; 
    right: 0;
    top: 150px;
`;

export const SmallDivContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 0%;
`;

export const TopBarBlueWrapper = styled.div`
  position: absolute;
  top: 200px;
  left: 0;
  width: 200px;
  height: 20px;
  left: 7%;
  background-color: ${azul_primario};
`;

export const FirtLinhaTitle = styled.h2`
  font-weight: 300;
  color: ${cinza_texto};
  font-size: 16px;
  text-align: center;
  margin-left: 40px;
  margin-right: 10px;
  position: ${props => props.firstTime ? 'absolute' : 'absolute'};
  font-family: ${font_base};
`;

export const LinhaTitle = styled.h2`
  font-weight: 300;
  color: ${cinza_texto};
  font-size: 16px;
  text-align: center;
  margin-left: 170px;
  margin-right: 10px;
  position: relative;
  font-family: ${font_base};
`;
export const TempoTitle = styled.h2`
  font-weight: 300;
  color: ${cinza_texto};
  font-size: 16px;
  text-align: center;
  font-family: ${font_base};
`;

export const TarifaTitle = styled.h2`
  font-weight: 300;
  color: ${cinza_texto};
  font-size: 16px;
  text-align: center;
  margin-left: 10px; 
  font-family: ${font_base};
`;

export const SelectMark = styled.button`
  height: 17px;
  width: 28px;
  background-color: ${branco_primario};
  border: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: ${props => props.color};
  font-family: ${font_base};
`;

export const MapButton = styled.button`
  height: 17px;
  width: 28px;
  background-color: ${branco_primario};
  border: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: ${azul_primario};
  cursor: pointer;
  font-family: ${font_base};
`;

export const InfoButton = styled.button`
  height: 17px;
  width: 28px;
  background-color: ${branco_primario};
  border: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: ${azul_primario};
  cursor: pointer;
  font-family: ${font_base};
`;

export const TempoTitleWrapper = styled.div`
 margin-left: 64.5%;

`; 

export const TarifaTitleWrapper = styled.div`
 margin-left: 76.3%;
`;  

export const MapButtonWrapper = styled.div`
  margin-left: 94%;
`;

export const InfoButtonWrapper = styled.div`
  margin-left: 89%;
`;

export const ResultWrapper = styled.div`
  height: 45px;
  width: 53%;
  margin-left: 10%;
`;

export const ResultTableWrapper = styled.div`
  margin-top: 10px;
`;

export const InfoContainer = styled.div`
  height: 100%;
  width: 40%;
  margin-left: 60%;
  margin-top: 30%;
  position: absolute;
`;

export const TopDocButtonDiv = styled.div`
  height: 5%;
  width: 40%;
  position: absolute;
  margin-top: -11.5%;
  margin-left: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  z-index: 800;
`;