import styled from 'styled-components';
import { font_base } from '../../../data/font';
import { azul_primario, azul_primario_light, branco_primario, cinza_texto } from '../../../data/color';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
`;

export const ModalContent = styled.div`
  background: ${branco_primario};
  border-radius: 2px;
  position: relative;
  width: 30%;
  height: 85%;
  overflow-y: auto;
  box-shadow: 0px 0px 20px;
  margin-top: 5%;
  margin-left: 70%;
`;

export const ModalInfoIntern = styled.div`
  position: absolute;
  width: 100%;
  heigt: 80%;
  margin-top: 20%;
  border-width: 0px;
  background: none;
  font-family: ${font_base};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: ${branco_primario};
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: ${font_base};
 
`;

export const StyledTopBar = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%; 
  height: 15%;
  background-color: ${azul_primario}; 
 
`;

export const  Title = styled.h1`
  font-size: 25px;
  color: ${branco_primario};
  margin: 10px;
  padding: 0;
  text-align: center;
  line-height: 50px;
  margin-top: 20;
  font-family: ${font_base};
`;
