import { React } from "react";
import { CampoTitulo, CampoDados, Linha } from "./styles";

import { FaStarOfLife, FaMoneyBillWave, FaPen, FaClock, FaPersonWalking, FaBus } from "react-icons/fa6";

const RotaInfoView = ({dados}) => {
    // Função para verificar se o valor é nulo ou indefinido e retornar "-" ou o valor formatado
    const formatarValor = (valor, isMonetario = false, multiplicarPorMil = false) => {
        if (valor === null || valor === undefined) return "-";
        let resultado = multiplicarPorMil ? valor * 1000 : valor;
        return isMonetario ? `R$ ${resultado.toFixed(2).toString().replace(".", ",")}` : `${resultado.toFixed(2)}`;
    };

    return (
        <>
            <CampoTitulo>
                <FaStarOfLife style={{ color: '#4D4D4D', fontSize: '10px' }} />
                <h> Sistema: </h>
            </CampoTitulo>
            <CampoDados>
                <h>{dados.sistema || "-"}</h>
            </CampoDados>
            <Linha />
            <CampoTitulo>
                <FaMoneyBillWave style={{ color: '#4D4D4D', fontSize: '10px' }} />
                <h> Tarifa: </h>
            </CampoTitulo>
            <CampoDados>
                <h>{formatarValor(dados.tarifa, true)}</h>
            </CampoDados>
            <Linha />
            <CampoTitulo>
                <FaPen style={{ color: '#4D4D4D', fontSize: '10px' }} />
                <h> Descrição: </h>
            </CampoTitulo>
            <CampoDados>
                <h>{dados.descricao || "-"}</h>
            </CampoDados>
            <Linha />
            <CampoTitulo>
                <FaClock style={{ color: '#4D4D4D', fontSize: '10px' }} />
                <h> Tempo: </h>
            </CampoTitulo>
            <CampoDados>
                <h>{dados.tempo ? `${dados.tempo} min` : "-"}</h>
            </CampoDados>
            <Linha />
            <CampoTitulo>
                <FaPersonWalking style={{ color: '#4D4D4D', fontSize: '10px' }} />
                <h> Distância para embarque: </h>
            </CampoTitulo>
            <CampoDados>
                <h>{formatarValor(dados.distancia_embarque, false, true)} m</h>
            </CampoDados>
            <Linha />
            <CampoTitulo>
                <FaBus style={{ color: '#4D4D4D', fontSize: '10px' }} />
                <h> Distância embarcada: </h>
            </CampoTitulo>
            <CampoDados>
                <h>{formatarValor(dados.distancia_embarcada)} km</h>
            </CampoDados>
            <Linha />
            <CampoTitulo>
                <FaBus style={{ color: '#4D4D4D', fontSize: '10px' }} />
                <h> Distância após desembarque: </h>
            </CampoTitulo>
            <CampoDados>
                <h>{formatarValor(dados.distancia_desembarque, false, true)} m</h>
            </CampoDados>
        </>
    );
};

export default RotaInfoView;
